import React from "react";
import { useEffect } from "react";
import Navbar from "./Navigate";
import Footer from "./Components/Footer";
import "./Course.css";
import JobPoster from "./Newchange/Jobposterbl";


function Jobportalpage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
    <JobPoster />
      <Footer />
    </>
  );
}

export default Jobportalpage;
