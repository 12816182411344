import React, { useState } from "react";
import "./JobDetailPage.css";
import tcs from './Assest/image.png';
import emailjs from 'emailjs-com';

const JobDetailPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Sending form data using EmailJS
    emailjs.sendForm(
      'service_zzmvdkh', // EmailJS Service ID
      'template_qx13ufo', // EmailJS Template ID
      e.target, // Form data from the event target
      'JjLBlxjqw0QoNFo7L' // EmailJS User ID (found in your EmailJS dashboard)
    )
    .then((result) => {
      console.log("Form submitted:", result.text);
      // Optionally, show a success message or reset the form
    })
    .catch((error) => {
      console.log("Error sending email:", error.text);
    });
  };

  return (
    <div className="jobfrontendjob1-container">
      {/* Hero Section with Banner Image */}
      <div className="jobfrontendjob1-hero">
        <div className="jobfrontendjob1-banner">
          <img
            src={tcs} // Replace with actual banner image URL
            alt="Job Banner"
            className="jobfrontendjob1-banner-img"
          />
        </div>
        <h1 className="jobfrontendjob1-title">TCS is Hiring: Front-End Developer (DSA Skills)</h1>
        <p className="jobfrontendjob1-subtitle">
          Join our team to build scalable, high-performance, and interactive web applications.
        </p>
      </div>

      {/* Main Content */}
      <div className="jobfrontendjob1-main-content">
        {/* Left Section: About and Requirements */}
        <div className="jobfrontendjob1-details">
          <h2 className="jobfrontendjob1-section-title">About TCS</h2>
          <p className="jobfrontendjob1-section-text">
            Tata Consultancy Services (TCS) is a global leader in IT services, consulting, and business solutions. With a presence in over 50 countries, TCS is renowned for its innovative approach, extensive portfolio of services, and commitment to sustainability. As part of the Tata Group, TCS embodies a culture of excellence, collaboration, and integrity. Join a company that values your growth and empowers you to make an impact worldwide.
          </p>

          <h2 className="jobfrontendjob1-section-title">About the Role</h2>
          <p className="jobfrontendjob1-section-text">
            TCS is hiring a passionate Front-End Developer with strong Data Structures and Algorithms (DSA) skills to join our team.
            You'll be working on developing robust, scalable web applications and solving complex technical problems.
          </p>

          <h2 className="jobfrontendjob1-section-title">Key Responsibilities</h2>
          <ul className="jobfrontendjob1-section-list">
            <li>Build and maintain interactive web applications using React.js</li>
            <li>Collaborate with the backend team for API integration</li>
            <li>Write clean and efficient code following best practices</li>
            <li>Improve application performance and scalability</li>
            <li>Use algorithms and data structures to solve technical challenges</li>
          </ul>

          <h2 className="jobfrontendjob1-section-title">Requirements</h2>
          <ul className="jobfrontendjob1-section-list">
            <li>Proficient in HTML, CSS, JavaScript (ES6+)</li>
            <li>Strong experience with React.js</li>
            <li>Solid understanding of Data Structures and Algorithms (DSA)</li>
            <li>Experience with Git and version control</li>
            <li>Familiarity with responsive design principles</li>
            <li>Excellent communication and problem-solving skills</li>
          </ul>

          <h2 className="jobfrontendjob1-section-title">Salary</h2>
          <p className="jobfrontendjob1-section-text">
            Salary: ₹6,00,000 - ₹9,00,000 per annum based on experience
          </p>
        </div>

        {/* Right Section: Get Skilled Form */}
        <div className="jobfrontendjob1-form">
          <h2 className="jobfrontendjob1-form-title">Get Skilled with Us!</h2>
          <p className="jobfrontendjob1-form-text">
            Fill out the form below to book a call with our team and get personalized training to improve your skills for this position.
          </p>
          <form onSubmit={handleSubmit} className="jobfrontendjob1-form-content">
            <div className="jobfrontendjob1-form-group">
              <label htmlFor="name" className="jobfrontendjob1-form-label">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="jobfrontendjob1-form-input"
                placeholder="Enter your full name"
                required
              />
            </div>
            <div className="jobfrontendjob1-form-group">
              <label htmlFor="email" className="jobfrontendjob1-form-label">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="jobfrontendjob1-form-input"
                placeholder="Enter your email address"
                required
              />
            </div>
            <div className="jobfrontendjob1-form-group">
              <label htmlFor="phone" className="jobfrontendjob1-form-label">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="jobfrontendjob1-form-input"
                placeholder="Enter your phone number"
                required
              />
            </div>
            <div className="jobfrontendjob1-form-group">
              <label htmlFor="message" className="jobfrontendjob1-form-label">Why do you want to apply?</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="jobfrontendjob1-form-input"
                placeholder="Explain why you're interested in this role"
                required
              />
            </div>
            <button type="submit" className="jobfrontendjob1-form-submit-btn">Book a Call</button>
          </form>
        </div>
      </div>

      {/* Training Provided Section */}
      <div className="jobfrontendjob1-training">
        <h2 className="jobfrontendjob1-section-title">Training We Provide</h2>
        <p className="jobfrontendjob1-section-text">
          We offer personalized training to help you get up to speed with the key technologies and concepts required for this role.
          Our training covers the following areas:
        </p>
        <ul className="jobfrontendjob1-section-list">
          <li>Mastering Data Structures and Algorithms (DSA)</li>
          <li>Hands-on experience with React.js</li>
          <li>Advanced problem-solving techniques</li>
          <li>Building scalable and high-performance web applications</li>
          <li>Version control and Git workflows</li>
          <li>Responsive design and cross-browser compatibility</li>
        </ul>
        <p className="jobfrontendjob1-section-text">
          Our training program is designed to ensure you're well-prepared to take on the challenges of this role with confidence!
        </p>
      </div>
    </div>
  );
};

export default JobDetailPage;
