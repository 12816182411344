import React, { useState, useEffect } from "react";
import "./Header.css";

function Header() {
 
 

  return (
    <>
      <div className="header">
        <div>
          <marquee className="headerh4">
            Secure your future career today with our Advanced Placement program{" "}
            – Don't wait, take the first step toward a brighter future now!
          </marquee>
        </div>

        {/* <div className="hdr">
          <div className="headertimer">
            <h1 className="headertimerh1">{formatTime(time)}</h1>
          </div>
          <div className="seat-info">
            <p className="headerp">
              Limited Seat ({seats}/{totalSeats})
            </p>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Header;
