import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com"; // Import emailjs-com
import "./Pricing.css";
import ondsa from "../../Assets/OnlineDSA.png";
import ofdsa from "../../Assets/OfflineDSA.png";

const courseData = [
  {
    id: 1,
    name: "Full Stack Development ",
    image: "https://sourcebae.com/blog/wp-content/uploads/2023/09/Full-Stack-Developer-980x462-1.png",
    cert: "ISO TSN Certified",
    jobAssistance: "100% Job Assistance",
    duration: "1:1 Mentorship",
    salary: "10LPA + Average",
    projects: "Real-World Projects",
    mentors: "Mentors from Top MNC",
    support: "24/7 Learning Support",
    mode: "25999",
    mode1: "Batch Start From 3rd feb",
    price: 15999,
    brochure: "https://bluink360.com/pdfdata/Bluink360%20Academy_%20Full%20Stack%20Development%20using%20MERN.pdf",
  },
  {
    id: 2,
    name: "DSA and System Design",
    image: "https://cdn.hackr.io/uploads/posts/large/1627884166eKgVi1YLix.png",
    cert: "ISO TSN Certified",
    jobAssistance: "100% Job Assistance",
    duration: "1:1 Mentorship",
    salary: "15LPA + Average",
    projects: "Real-World Projects",
    mentors: "Mentors from Top MNC",
    support: "24/7 Learning Support",
    mode: "₹25,000",
    mode1: "Batch Start From 5th feb",
    price: 15999,
    brochure: "https://bluink360.com/pdfdata/Bluink360%20Academy_%20DSA%20and%20System%20Design.pdf",
  },
  // Add other courses...
  {
    id: 3,
    name: "AI and ML ",
    image : "https://erode-sengunthar.ac.in/wp-content/uploads/2024/02/aiml-image-1.png", // Replace with appropriate image variable
    cert: "ISO TSN Certified",
    jobAssistance: "100% Job Assistance",
    duration: "1:1 Mentorship",
    salary: "12LPA + Average",
    projects: "Real-World Projects",
    mentors: "Mentors from Top MNC",
    support: "24/7 Learning Support",
    mode: "₹25,000",
    mode1: "Batch Start From 5th feb",
    price: 15999,
    brochure: "https://bluink360.com/pdfdata/Bluink360%20Academy_%20AI%20and%20Machine%20Learning.pdf", // Replace with correct link
  },
  {
    id: 4,
    name: "Data Analytics ",
    image: "https://media.geeksforgeeks.org/wp-content/uploads/20230922185402/Data-Analytics-.webp", // Replace with appropriate image variable
    cert: "ISO TSN Certified",
    jobAssistance: "100% Job Assistance",
    duration: "1:1 Mentorship",
    salary: "12LPA + Average",
    projects: "Real-World Projects",
    mentors: "Mentors from Top MNC",
    support: "24/7 Learning Support",
    mode: "₹25,000",
    mode1: "Batch Start From 7th feb",
    price: 15999,
    brochure: "https://bluink360.com/pdfdata/Bluink360%20Academy_%20Data%20Analytics.pdf", // Replace with correct link
},
{
  id: 5,
  name: "Generative AI",
  image: "https://s3-us-west-2.amazonaws.com/speedybrandimages/tmp_6b7a260e-a717-4625-b845-a7f8ee929ee3.webp", // Replace with appropriate image variable
  cert: "ISO TSN Certified",
  jobAssistance: "100% Job Assistance",
  duration: "1:1 Mentorship",
  salary: "18LPA + Average",
  projects: "Real-World Projects",
  mentors: "Mentors from Top MNC",
  support: "24/7 Learning Support",
  mode: "₹25,000",
  mode1: "Batch Start From 10th feb",
  price: 15999,
  brochure: "https://bluink360.com/pdfdata/Bluink360%20Academy_%20Generative%20AI.pdf", // Replace with correct link
},
{
    id: 6,
    name: "Professional Soft Skills & Interview Training Program",
    image: "https://media.geeksforgeeks.org/wp-content/cdn-uploads/20200629174328/GeeksforGeeks-Complete-Interview-Preparation-Course-%E2%80%93-Get-a-Job-in-your-Dream-Company.png", // Replace with appropriate image variable
    cert: "ISO TSN Certified",
    jobAssistance: "100% Job Assistance",
    duration: "1:1 Mentorship",
    salary: "8LPA + Average",
    projects: "Real-World Projects",
    mentors: "Mentors from Top MNC",
    support: "24/7 Learning Support",
    mode: "₹25,000",
    mode1: "Batch Start From 10th feb",
    price: 15999,
    brochure: "https://bluink360.com/pdfdata/", // Replace with correct link
},

];

function CourseCard() {
  const [showModal, setShowModal] = useState(false);
  const [selectedBrochure, setSelectedBrochure] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
  });
  const [loading, setLoading] = useState(false);

  const getRandomSeats = () => Math.floor(Math.random() * (20 - 11 + 1)) + 3;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleBrochureClick = (brochureLink) => {
    setSelectedBrochure(brochureLink);
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Send email using EmailJS
    emailjs
      .send(
        "service_g9uo8nq",
        "template_n6jmnyc",
        formData,
        "mCWwpUYcmFY2sWUd4"
      )
      .then(
        (response) => {
          console.log("Email sent successfully", response);
          // Redirect to brochure after form submission
          setTimeout(() => {
            window.open(selectedBrochure, "_blank");
            setShowModal(false);
            setLoading(false);
          }, 2000);
        },
        (error) => {
          console.error("Error sending email", error);
          setLoading(false);
        }
      );
  };

  const handlePayment = (course, mode) => {
    const price = mode === "Online" ? course.price : course.price + 10000; // Assuming offline price is ₹10,000 higher
    if (!window.Razorpay) {
      alert("Razorpay SDK not loaded. Please try again later.");
      return;
    }

    const options = {
      key: "rzp_live_X7WnUIRgMTFglX",
      amount: price * 100, // Amount in paise
      currency: "INR",
      name: "Bluink360",
      description: `Payment for ${course.name} (${mode} Mode)`,
      image: "https://bluink360.com/logo.png",
      handler: function (response) {
        alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
      },
      prefill: {
        name: "Your Name",
        email: "user@example.com",
        contact: "9876543210",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();

    razorpay.on("payment.failed", function (response) {
      alert(`Payment failed! Error: ${response.error.description}`);
      console.error("Payment Failure Details:", response.error);
    });
  };

  return (
    <div className="course-section">
      <h1 className="pri-skillbuild-title">Our Courses</h1>
      <div className="pri-coursecon">
        {courseData.map((course) => (
          <div className="pri-dsa-course-card" key={course.id}>
            <img
              src={course.image}
              alt={`${course.name} Icon`}
              className="pri-course-image"
            />
            <div className="pri-dsa-content">
              <span className="pri-dsa-nsdc-cert">{course.cert}</span>
              <h3 className="pri-course-title">{course.name}</h3>
              <div className="pri-dsa-info">
                <div>
                  <i className="fas fa-briefcase"></i> {course.jobAssistance}
                </div>
                <div>
                  <i className="fas fa-user-tie"></i> {course.duration}
                </div>
              </div>
              <div className="pri-dsa-info">
                <div>
                  <i className="fas fa-money-bill-wave"></i> {course.salary}
                </div>
                <div>
                  <i className="fas fa-project-diagram"></i> {course.projects}
                </div>
              </div>
              <span className="pri-dsa-online-badge">
                <h6> {course.mode1} </h6>
              </span>
              <br />
              {/* <h6 >Batch Start from 4th Feb</h6> */}
              <h6 className="blinking-text" >{getRandomSeats()} Seats Remaining</h6>

              {/* Add both "Enroll Now" buttons */}
              <div className="button-container">
  <button
    className="pri-go-button"
    onClick={() => handlePayment(course, "Online")}
  >
    Enroll Now (Online - ₹{course.price})
  </button>
  <button
    className="pri-go-button"
    onClick={() => handlePayment(course, "Offline")}
  >
    Enroll Now (Offline - ₹{course.price + 10000})
  </button>
</div>

              <button
                className="pri-brochure-link"
                onClick={() => handleBrochureClick(course.brochure)}
              >
                BROCHURE <i className="fas fa-download"></i>
              </button>
            </div>
          </div>
        ))}
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Fill out this form to access the brochure</h2>
            <form onSubmit={handleFormSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Mobile Number:
                <input
                  type="tel"
                  name="contact"
                  value={formData.contact}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <button type="submit" className="submit-button">
                Submit
              </button>
            </form>

            {loading && (
              <div className="loading-container">
                <div className="spinner"></div>
                <p>Please wait, we are redirecting...</p>
              </div>
            )}

            <button
              className="close-button"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseCard;
