import React from "react";
import "./AboutTheTeam.css"; // Create and link the CSS file for styling
import m1 from '../../Assets/image.png'

const teamMembers = [
    {
      name: "Sumit Singh",
      role: "Data Scientist",
      exp : "5 year | TCS",
      img: m1,
      socials: [
        { platform: "Instagram", url: "https://www.instagram.com/yourprofile", icon: "https://img.icons8.com/?size=100&id=Xy10Jcu1L2Su&format=png&color=000000" },
        { platform: "LinkedIn", url: "https://www.linkedin.com/in/leonardkrasner", icon: "https://img.icons8.com/?size=100&id=xuvGCOXi8Wyg&format=png&color=000000" },
        { platform: "Email", url: "mailto:leonard@example.com", icon: "https://img.icons8.com/?size=100&id=P7UIlhbpWzZm&format=png&color=000000" },
      ],
    },
    {
      name: "Deepak Tripathi",
      role: "Software Developer",
      exp : "10 year | Ex-HCL",
      img: m1,
      socials: [
        { platform: "Instagram", url: "https://www.instagram.com/yourprofile", icon: "https://img.icons8.com/?size=100&id=Xy10Jcu1L2Su&format=png&color=000000" },
        { platform: "LinkedIn", url: "https://www.linkedin.com/in/leonardkrasner", icon: "https://img.icons8.com/?size=100&id=xuvGCOXi8Wyg&format=png&color=000000" },
        { platform: "Email", url: "mailto:leonard@example.com", icon: "https://img.icons8.com/?size=100&id=P7UIlhbpWzZm&format=png&color=000000" },
      ],
    },
    {
      name: "Sanjay Kumar",
      role: "Senior Ai Developer",
      exp : "7 year | Ex-Infosys",
      img: m1,
      // socials: [
      //   { platform: "Instagram", url: "https://www.instagram.com/yourprofile", icon: "https://img.icons8.com/?size=100&id=Xy10Jcu1L2Su&format=png&color=000000" },
      //   { platform: "LinkedIn", url: "https://www.linkedin.com/in/leonardkrasner", icon: "https://img.icons8.com/?size=100&id=xuvGCOXi8Wyg&format=png&color=000000" },
      //   { platform: "Email", url: "mailto:leonard@example.com", icon: "https://img.icons8.com/?size=100&id=P7UIlhbpWzZm&format=png&color=000000" },
      // ],
    },
    {
      name: "Prakash Raj",
      role: "Full Stack Developer",
      exp : "10 year | Google",
      img: m1,
      // socials: [
      //   { platform: "Instagram", url: "https://www.instagram.com/yourprofile", icon: "https://img.icons8.com/?size=100&id=Xy10Jcu1L2Su&format=png&color=000000" },
      //   { platform: "LinkedIn", url: "https://www.linkedin.com/in/leonardkrasner", icon: "https://img.icons8.com/?size=100&id=xuvGCOXi8Wyg&format=png&color=000000" },
      //   { platform: "Email", url: "mailto:leonard@example.com", icon: "https://img.icons8.com/?size=100&id=P7UIlhbpWzZm&format=png&color=000000" },
      // ],
    },
  ];
  

const AboutTheTeam = () => {
  return (
    <div className="Abouttheteam">
      <h2>Meet our Dynamic <span className="highlight"> Exprienced Mentors</span></h2>
      <p>
        We're a dynamic group of individuals who are passionate about what we
        do and dedicated to delivering the best results.
      </p>
      <div className="team-cardss">
  {teamMembers.map((member, index) => (
    <div key={index} className="team-card">
      <img src={member.img} alt={member.name} className="team-photo" />
      <h3>{member.name}</h3>
      <center><p>{member.role}</p></center>
      <center><p>{member.exp}</p></center>
      {/* <div className="social-links">
        {member.socials.map((social, idx) => (
          <a
            key={idx}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={social.platform}
          >
            <img src={social.icon} alt={social.platform} />
          </a>
        ))}
      </div> */}
    </div>
  ))}
</div>
    </div>
  );
};

export default AboutTheTeam;
